import { cx } from "class-variance-authority";
import { useKeenSlider } from "keen-slider/react";
import Image from "next/image";
import * as React from "react";
import { SocialLink } from "./SocialLink";
import { CarouselProps } from "./types";

export function Carousel({
  initialIndex,
  items,
  isStatic = false,
  title,
}: CarouselProps) {
  const initSlide = React.useMemo(() => {
    if (initialIndex && initialIndex > 0 && initialIndex < items.length) {
      return initialIndex;
    }
    return 0;
  }, [initialIndex, items.length]);

  const [currentSlide, setCurrentSlide] = React.useState(initSlide);
  const [currentThumb, setCurrentThumb] = React.useState(initSlide);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    selector: "[data-carousel-item]",
    initial: initSlide,
    loop: !isStatic,
    mode: "free-snap",
    slides: isStatic
      ? {
          perView: "auto",
          spacing: 20,
        }
      : undefined,

    breakpoints: isStatic
      ? {
          "(max-width: 767px)": {
            slides: {
              perView: "auto",
              origin: "center",
              spacing: 10,
            },
          },
        }
      : {
          "(max-width: 499.5px)": {
            slides: {
              origin: "center",
              perView: 1.2,
              spacing: 10,
            },
          },

          "(min-width: 500px)": {
            slides: {
              origin: "center",
              perView: 2,
              spacing: 20,
            },
          },

          "(min-width: 768px)": {
            slides: {
              origin: "center",
              perView: 3.5,
              spacing: 20,
            },
          },
          "(min-width: 992px)": {
            slides: {
              origin: "center",
              perView: 4,
              spacing: 5,
            },
          },
        },
    slideChanged(s) {
      const index = s.track.details.rel;
      const isDuplicated = items.length < index + 1;

      setCurrentSlide(index);
      setCurrentThumb(isDuplicated ? index - items.length : index);
    },
  });

  const onThumbClick = React.useCallback(
    (idx: number) => {
      setCurrentSlide(idx);

      instanceRef.current?.moveToIdx(idx);
    },
    [instanceRef]
  );

  const sliderItems = React.useMemo(() => {
    return isStatic ? items : [...items, ...items];
  }, [isStatic, items]);
  return (
    <div className="w-full bg-primary">
      <div className="container px-0">
        {title && (
          <h2 className="text-2xl from-tablet:text-4xl font-medium pt-6 text-center text-secondary pb-5">
            {title}
          </h2>
        )}
        <div
          ref={sliderRef}
          className={cx(
            "keen-slider flex w-full overflow-hidden",
            isStatic && "justify-around gap-5 from-tablet:pb-3"
          )}
        >
          {sliderItems.map(({ image, name, subName, text, socials }, index) => {
            const isActive = index === currentSlide;
            if (!image) {
              return null;
            }

            return (
              <div
                key={index}
                data-carousel-item
                className={cx(
                  "border transition-colors",
                  isActive && !isStatic
                    ? "border-secondary bg-secondary text-primary"
                    : "border-primary bg-primary text-secondary ",
                  isStatic &&
                    "max-w-72 flex-shrink-0 group hover:border-secondary hover:bg-secondary hover:text-primary",
                  isStatic &&
                    "only-mobile:border-secondary only-mobile:bg-secondary only-mobile:text-primary",
                  !isActive && !isStatic && "cursor-pointer"
                )}
                onClick={() => {
                  if (!isActive) {
                    onThumbClick(index);
                  }
                }}
              >
                <div className="w-full aspect-h-1 relative">
                  <div
                    className={cx(
                      "absolute top-0 left-0 w-full h-full transition-all",
                      !isActive && !isStatic && "grayscale",
                      isStatic &&
                        "grayscale group-hover:grayscale-0 only-mobile:grayscale-0"
                    )}
                  >
                    <Image
                      src={image}
                      alt={name}
                      fill
                      className="object-contain"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-5 py-5 px-2 transition-colors text-center">
                  <div>
                    <h3 className="font-bold text-base">{name}</h3>
                    {subName && <p>{subName}</p>}
                  </div>

                  {socials && socials.length > 0 && (
                    <div className="flex gap-1 w-full justify-center items-center">
                      {socials.map((social, index) => {
                        return (
                          <SocialLink
                            key={index}
                            isActive={isActive}
                            isStatic={isStatic}
                            {...social}
                          />
                        );
                      })}
                    </div>
                  )}
                  {text && <p dangerouslySetInnerHTML={{ __html: text }} />}
                </div>
              </div>
            );
          })}
        </div>

        <div
          className={cx(
            "flex gap-2 w-full items-center justify-center my-3 h-5 only-mobile:px-5",
            isStatic && "from-tablet:hidden"
          )}
        >
          {items.map((_, index) => {
            const isActive = index === currentThumb;
            return (
              <div
                key={index}
                onClick={() => {
                  onThumbClick(index);
                }}
                className={cx(
                  "transition-all cursor-pointer only-mobile:flex-1 only-mobile:h-[10px]",
                  isActive
                    ? "w-5 h-5 bg-action"
                    : "w-[10px] h-[10px] bg-secondary"
                )}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
