import { Scroll } from "@bs/ui";
import { cx } from "class-variance-authority";
import * as React from "react";
import { InView } from "react-intersection-observer";
import { ColumnSectionProps } from "./types";

const DEFAULT_ANIMATION_TIME = 500;

export function ColumnSection({ items, animationSpeed }: ColumnSectionProps) {
  const [visible, setVisible] = React.useState(false);

  const ANIMATION_TIME = React.useMemo(() => {
    return animationSpeed || DEFAULT_ANIMATION_TIME;
  }, [animationSpeed]);

  return (
    <InView triggerOnce={true} onChange={setVisible} rootMargin="-100px">
      <div className="w-full bg-primary relative z-10">
        <div className="container simplebar-content-no-p">
          <Scroll className="py-0 [&_.simplebar-track]:bottom-3">
            <div className="flex py-10">
              {items.map((column, index) => {
                return (
                  <div
                    key={column.id}
                    style={{
                      // @ts-expect-error missing types for css vars
                      "--column-animation-duration": `${ANIMATION_TIME}ms`,
                    }}
                    className="px-6 py-20 flex-1 min-w-64 relative group"
                  >
                    <div
                      style={{
                        // @ts-expect-error missing types for css vars
                        "--column-opacity-delay": `${
                          ANIMATION_TIME * (index + 1)
                        }ms`,
                      }}
                      className={cx(
                        "flex flex-col gap-14 text-secondary relative z-20 bg-transparent",
                        visible &&
                          "transition-opacity delay-[var(--column-opacity-delay)] duration-[var(--column-animation-duration)]",
                        visible ? "opacity-1" : "opacity-0"
                      )}
                    >
                      <h3 className="text-xl font-medium">{column.title}</h3>
                      <p
                        className="text-base leading-5"
                        dangerouslySetInnerHTML={{ __html: column.text }}
                      />
                    </div>
                    <div
                      className="absolute top-0 left-0 w-full h-full pointer-events-none"
                      style={{
                        // @ts-expect-error missing types for css vars
                        "--column-animation-delay": `${
                          ANIMATION_TIME * index
                        }ms`,
                        "--column-animation-delay-last": `${
                          ANIMATION_TIME * (index + 1)
                        }ms`,
                      }}
                    >
                      <div
                        data-mask-left-bottom
                        className={cx(
                          "bg-primary absolute w-full z-10 pt-1",
                          "group-even:top-0 group-even:right-1 ",
                          "group-odd:bottom-0 group-odd:right-1",
                          "h-full group-first:h-[calc(100%+40px)]",
                          visible &&
                            "animate-[columnAnimation_var(--column-animation-duration)_ease_var(--column-animation-delay)_1_normal_forwards]"
                        )}
                      />
                      <div
                        data-mask-last
                        className={cx(
                          "bg-primary hidden group-last:block",
                          "absolute -bottom-10 right-0 z-10",
                          "h-[calc(100%+40px)] w-1",
                          visible &&
                            "animate-[columnAnimation_var(--column-animation-duration)_ease_var(--column-animation-delay-last)_1_normal_forwards]"
                        )}
                      />
                      <div
                        data-left
                        className="absolute bottom-0 left-0 h-[calc(100%+40px)] w-1 bg-secondary hidden group-first:block"
                      />
                      <div
                        data-bottom
                        className="absolute bottom-0 left-0 h-1 w-full bg-secondary group-even:hidden"
                      />
                      <div
                        data-right
                        className="absolute top-0 right-0 h-full w-1 bg-secondary group-last:h-[calc(100%+40px)]"
                      />
                      <div
                        data-top
                        className="absolute top-0 left-0 h-1 w-full bg-secondary group-odd:hidden"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </Scroll>
        </div>
      </div>
    </InView>
  );
}
