import { Icon } from "@bs/icons";
import { Transition } from "@headlessui/react";
import { cx } from "class-variance-authority";
import * as React from "react";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import { ActionTrigger } from "../../Action";
import { PlayerAnimation, PlayerAnimationRef } from "../Player/PlayerAnimation";
import { getPercentFromIndex } from "../helpers/getPercentFromIndex";
import { BannerAnimationProps } from "../types";

export function MobileContent({
  items,
  defaultOpenedOnMobile,
}: BannerAnimationProps) {
  const playerRef = React.useRef<PlayerAnimationRef | null>(null);
  const sectionRef = React.useRef<HTMLDivElement | null>(null);
  const [expandedElement, setExpandedElement] = React.useState(() => {
    if (
      defaultOpenedOnMobile &&
      defaultOpenedOnMobile > 0 &&
      defaultOpenedOnMobile < items.length
    ) {
      return defaultOpenedOnMobile;
    }
    return 0;
  });

  const onTriggerClick = React.useCallback(
    (index: number) => {
      const newIndex = expandedElement === index ? 0 : index;

      setExpandedElement(newIndex);
      const percent = getPercentFromIndex(newIndex, items.length - 1);

      if (playerRef?.current) {
        playerRef.current.changeProgress(percent);
      }

      if (sectionRef?.current) {
        scrollIntoView(sectionRef.current, {
          behavior: "smooth",
          block: "start",
          inline: "center",
        });
      }
    },
    [items.length, expandedElement]
  );

  return (
    <div className="w-full only-mobile:py-12 pb-12 bg-primary" ref={sectionRef}>
      <div className="w-full flex flex-col gap-4">
        <div className="container hidden from-tablet:block">
          <div className="w-full flex items-center justify-center">
            <div className="w-full max-w-96">
              <PlayerAnimation ref={playerRef} />
            </div>
          </div>
        </div>
        <div className="w-full bg-secondary py-4 ">
          <div className="container flex flex-col gap-4">
            {items.map((el, index) => {
              const open = index === expandedElement;
              const showArrow = (index === 0 && !open) || index > 0;
              return (
                <div key={el.id}>
                  <div
                    className={cx(
                      "flex w-full gap-2 items-center text-xl text-left text-primary",
                      showArrow && "cursor-pointer",
                      index === 0 && open && "cursor-default"
                    )}
                    onClick={() => {
                      onTriggerClick(index);
                    }}
                  >
                    <span>{el.title}</span>
                    {showArrow && (
                      <Icon
                        name="arrow-right"
                        className={cx(
                          "h-5 w-5 text-primary transition-transform",
                          open ? "rotate-90 transform" : ""
                        )}
                      />
                    )}
                  </div>
                  <Transition
                    show={open}
                    enter="transition duration-250 ease-in"
                    enterFrom="transform opacity-0"
                    enterTo="transform opacity-100"
                  >
                    <div className="pt-4 flex flex-col gap-4 text-primary">
                      <p dangerouslySetInnerHTML={{ __html: el.text }} />
                      {el.action && (
                        <div>
                          <ActionTrigger
                            action={{
                              ...el.action,
                              variant: "PRIMARY",
                              size: "SMALL",
                            }}
                            className="w-full"
                          />
                        </div>
                      )}
                    </div>
                  </Transition>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
