import { cx } from "class-variance-authority";
import Image from "next/image";

import { ActionTrigger } from "../Action";
import { BannerProps } from "./types";

export function Banner({
  image,
  imageAlt,
  mobileImage,
  mobileImageAlt,
  title,
  text,
  action,
  position = "LEFT",
  variant = "LIGHT",
}: BannerProps) {
  const isLight = variant === "LIGHT";

  if (!image) {
    return null;
  }

  return (
    <div
      className={cx(
        "w-full",
        isLight ? "bg-secondary text-primary" : "bg-primary text-secondary"
      )}
    >
      <div className="container relative py-6 from-desktop:py-10">
        <div
          className={cx(
            "flex flex-col justify-between items-center gap-11 from-desktop:gap-14",
            position === "RIGHT"
              ? "from-desktop:flex-row-reverse"
              : "from-desktop:flex-row"
          )}
        >
          <div className="from-desktop:hidden">
            <h2 className="text-2xl">{title}</h2>
          </div>
          <div className="relative w-full from-desktop:w-1/2 min-h-80 max-h-[500px] from-desktop:flex-shrink-0 aspect-h-1">
            <Image
              src={image}
              alt={imageAlt}
              fill
              sizes="100vw"
              className={cx(
                "object-contain",
                mobileImage && "hidden from-desktop:block"
              )}
            />
            {mobileImage && (
              <Image
                src={mobileImage}
                alt={mobileImageAlt || ""}
                fill
                sizes="100vw"
                className="object-contain from-desktop:hidden"
              />
            )}
          </div>
          <div className="flex flex-col gap-11 from-desktop:gap-14 h-full w-full relative">
            <h2 className="text-4xl font-medium to-desktop:hidden">{title}</h2>
            {text && (
              <p
                className="text-base from-tablet:text-lg from-desktop:text-base"
                dangerouslySetInnerHTML={{ __html: text }}
              />
            )}
            {action && (
              <div>
                <ActionTrigger
                  action={{
                    ...action,
                    variant: isLight ? "PRIMARY" : "SECONDARY",
                    size: "SMALL",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
