import { Icon, IconType } from "@bs/icons";
import { cx } from "class-variance-authority";
import { SocialNode, SocialType } from "./types";

interface SocialMap {
  iconType: IconType;
  hoverColor: string;
  title: string;
}

const socialMaps: Record<SocialType, SocialMap> = {
  FACEBOOK: {
    iconType: "facebook",
    hoverColor: "#3b5998",
    title: "Facebook",
  },
  LINKEDIN: {
    iconType: "linkedin",
    hoverColor: "#0077b5",
    title: "Linkedin",
  },
};

interface Props extends SocialNode {
  isActive: boolean;
  isStatic: boolean;
}

export function SocialLink({ link, type, isActive, isStatic }: Props) {
  if (!(type in socialMaps)) {
    return;
  }

  const { iconType, hoverColor, title } = socialMaps[type];

  return (
    <div
      // @ts-expect-error missing types for css vars
      style={{ "--socialHoverColor": hoverColor }}
    >
      <a
        href={link?.url || "#"}
        target={link?.target}
        title={title}
        className={cx(
          "hover:!text-[var(--socialHoverColor)]",
          isActive && !isStatic ? "text-primary" : "text-secondary",
          isStatic &&
            "text-secondary group-hover:text-primary only-mobile:text-primary",
          !isActive && !isStatic && "pointer-events-none"
        )}
      >
        <Icon name={iconType} />
        <span className="sr-only">{title}</span>
      </a>
    </div>
  );
}
