import { CopyBlock, dracula } from "react-code-blocks";
import { CodeBlocksProps } from "./types";

export function CodeBlocks({
  code,
  codeLanguage,
  showLineNumbers,
  startingLineNumber,
}: CodeBlocksProps) {
  return (
    <div className="w-full bg-primary">
      <div className="container p-0 text-sm max-w-[736px] overflow-auto bg-[#282a36]">
        <CopyBlock
          text={code}
          language={codeLanguage}
          showLineNumbers={showLineNumbers}
          startingLineNumber={startingLineNumber}
          wrapLongLines={false}
          theme={dracula}
        />
      </div>
    </div>
  );
}
