import { ButtonAction } from "./Actions/ButtonAction";
import { ContactForm } from "./Actions/ContactForm";
import { LinkAction } from "./Actions/LinkAction";
import { isButtonAction } from "./helpers/isButtonAction";
import { isContactFormAction } from "./helpers/isContactFormAction";
import { isLinkAction } from "./helpers/isLinkAction";
import { ActionTriggerProps } from "./types";

export function ActionTrigger({
  action,
  className,
  disabled = false,
  onClick,
}: ActionTriggerProps) {
  if (isContactFormAction(action)) {
    return (
      <ContactForm className={className} disabled={disabled} action={action} />
    );
  }
  if (isButtonAction(action)) {
    return (
      <ButtonAction
        className={className}
        disabled={disabled}
        action={action}
        onClick={onClick}
      />
    );
  }
  if (isLinkAction(action)) {
    return (
      <LinkAction
        className={className}
        disabled={disabled}
        action={action}
        onClick={onClick}
      />
    );
  }
}
