import { cx } from "class-variance-authority";
import * as React from "react";
import { InView } from "react-intersection-observer";
import { SocialLink } from "./SocialLink";
import { ContactProps } from "./types";

const DEFAULT_ANIMATION_TIME = 600;

export function Contact({ items, animationSpeed }: ContactProps) {
  const [isVisible, setVisible] = React.useState(false);
  const ANIMATION_TIME = React.useMemo(() => {
    return animationSpeed || DEFAULT_ANIMATION_TIME;
  }, [animationSpeed]);

  return (
    <InView triggerOnce={true} onChange={setVisible} rootMargin="-200px">
      <div className="w-full">
        <div className="container relative">
          <div
            className="hidden from-desktop:block"
            style={{
              // @ts-expect-error missing types for css vars
              "--contact-animation": `${ANIMATION_TIME}ms`,
              "--contact-animation-delay": `${ANIMATION_TIME * 0.2083}ms`,
            }}
          >
            <div className="h-full w-1 bg-secondary absolute left-0 top-0" />
            <div
              className={cx(
                "absolute top-0 left-0 w-1 h-full bg-primary",
                isVisible &&
                  "animate-[tabsHeightAnimation_var(--contact-animation)_ease_var(--contact-animation-delay)_1_normal_forwards]"
              )}
            />
            <div className="h-full w-1 bg-secondary absolute right-0 top-0" />
            <div
              className={cx(
                "absolute bottom-0 right-0 w-1 h-full bg-primary",
                isVisible &&
                  "animate-[tabsHeightAnimation_var(--contact-animation)_ease_var(--contact-animation)_1_normal_forwards]"
              )}
            />
          </div>
          <div className="h-screen  max-h-[512px] flex justify-center items-center flex-col gap-5 text-center">
            <h2 className="logo-4xl text-secondary from-tablet:logo-6xl from-desktop:logo-8xl tracking-widest drop-shadow-[0_0_5px_rgba(0,0,0,0.7)]">
              Better<span className="text-active">steps.</span>
            </h2>
            <div className="mt-14 w-full">
              <div className="grid from-desktop:grid-cols-4 gap-4 p-4 grid-cols-2 grid-rows-4">
                {items.map((item, i) => {
                  // Obliczanie, czy komórka jest w kolumnie parzystej czy nieparzystej na podstawie indexu
                  const index = i + 1;
                  const isEvenCol = index % 4 === 1 || index % 4 === 2; // Dla układu 4-kolumnowego na desktopie

                  return (
                    <div
                      key={item.id}
                      className={cx(
                        "to-desktop:odd:order-1 to-desktop:even:order-2 from-tablet:text-center",
                        isEvenCol ? "text-left" : "text-right"
                      )}
                    >
                      {item.type === "SIMPLE" && (
                        <span className="text-white">{item.description}</span>
                      )}
                      {item.type === "SOCIAL" && <SocialLink {...item} />}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </InView>
  );
}
