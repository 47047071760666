import { cx } from "class-variance-authority";
import Image from "next/image";
import React from "react";
import { FAQItem } from "./FAQItem";
import { FaqProps } from "./types";

export function FAQ({
  title,
  variant,
  media,
  mobileMedia,
  position,
  defaultOpened,
  items,
}: FaqProps) {
  const isLight = variant === "LIGHT";

  return (
    <div
      className={cx(
        "w-full",
        isLight ? "bg-secondary text-primary" : "bg-primary text-secondary"
      )}
    >
      <div className="container relative py-6 from-desktop:py-10">
        <div
          className={cx(
            "flex flex-col justify-between items-center gap-11 from-desktop:gap-14",
            position === "RIGHT"
              ? "from-desktop:flex-row-reverse"
              : "from-desktop:flex-row"
          )}
        >
          {title && (
            <div className="from-desktop:hidden">
              <h2 className="text-2xl">{title}</h2>
            </div>
          )}
          {media && (
            <div className="relative to-desktop:hidden w-1/2 min-h-80 max-h-[500px] flex-shrink-0 aspect-h-1">
              <Image
                src={media.src}
                alt={media.alt || ""}
                fill
                sizes="100vw"
                className="object-contain"
              />
            </div>
          )}
          {mobileMedia && (
            <div className="relative w-full from-desktop:hidden min-h-80 max-h-[500px] aspect-h-1">
              <Image
                src={mobileMedia.src}
                alt={mobileMedia.alt || ""}
                fill
                sizes="100vw"
                className="object-contain"
              />
            </div>
          )}

          <div className="flex flex-col gap-11 from-desktop:gap-14 h-full w-full relative">
            {title && (
              <h2 className="text-4xl font-medium to-desktop:hidden">
                {title}
              </h2>
            )}
            <div
              className={cx(
                "flex flex-col gap-3 divide-y ",
                isLight ? "divide-gray" : "divide-slate-700"
              )}
            >
              {items.map((item, index) => {
                return (
                  <FAQItem
                    key={index}
                    isLight={isLight}
                    isOpen={index === defaultOpened}
                    {...item}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
