import { BaseButton } from "@bs/ui";
import * as React from "react";
import { Link } from "../../Link";
import { showCookieSettings } from "../helpers/cookieSettings";
import {
  ActionTriggerProps,
  ButtonLinkActionData,
  LinkActionData,
} from "../types";

interface Props extends ActionTriggerProps {
  action: ButtonLinkActionData | LinkActionData;
  disabled: boolean;
}

export function LinkAction({ action, disabled, className, onClick }: Props) {
  const onClickHandler = React.useCallback(() => {
    if (!disabled && onClick) {
      onClick();
    }
  }, [disabled, onClick]);

  if (action.type === "BUTTON_LINK") {
    const { size, id, variant, title, action: link } = action;
    return (
      <BaseButton
        variant={variant}
        size={size}
        onClick={onClickHandler}
        disabled={disabled}
        className={className}
        asChild={true}
      >
        <Link id={id} href={link?.url} target={link?.target}>
          {title}
        </Link>
      </BaseButton>
    );
  }

  const { id, title, action: link } = action;

  return (
    <Link
      id={id}
      href={link?.url}
      target={link?.target}
      onClick={(e) => {
        if (link?.url === "#cookie.instance.management") {
          e.preventDefault();
          showCookieSettings();
          return;
        }

        onClickHandler();
      }}
    >
      {title}
    </Link>
  );
}
