import * as React from "react";
import { DesktopContent } from "./ContentView/DesktopContent";
import { MobileContent } from "./ContentView/MobileContent";
import { BannerAnimationProps } from "./types";

export function BannerAnimation(props: BannerAnimationProps) {
  const isDesktop = useIsDesktop();

  return (
    <div className="w-full">
      {isDesktop && <DesktopContent {...props} />}
      {!isDesktop && <MobileContent {...props} />}
    </div>
  );
}

function useIsDesktop() {
  return React.useSyncExternalStore(
    (onStoreChange) => {
      const aborter = new AbortController();
      window
        .matchMedia("(min-width: 992px)")
        .addEventListener("change", onStoreChange, {
          signal: aborter.signal,
        });
      return () => {
        aborter.abort();
      };
    },
    () => window.matchMedia("(min-width: 992px)").matches,
    () => false
  );
}
