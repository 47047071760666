import { animated, easings, useSprings } from "@react-spring/web";
import { cx } from "class-variance-authority";
import random from "lodash-es/random";
import * as React from "react";

interface Props {
  text: string;
  animationSpeed?: number;
}

export function AnimateSlogan({ text, animationSpeed = 1000 }: Props) {
  const [dotVisible, setDotVisible] = React.useState(false);
  const completedAnimationsRef = React.useRef(0);
  const animatedText = text.split("");

  const animatedMap = React.useMemo(() => {
    let index = 2;
    return animatedText.map(() => {
      if (index === 2) {
        index = 0;
        return "up";
      }

      index++;
      return "down";
    });
  }, [animatedText]);

  const [springs] = useSprings(animatedText.length, (index) => {
    const isUp = animatedMap[index] === "up";
    const nr = Math.abs(index % 10) + 1;
    return {
      from: {
        transform: `translateY(${isUp ? "-60%" : "-10%"})`,
      },
      to: { transform: `translateY(${isUp ? "0" : "-75%"})` },
      config: {
        duration: random(animationSpeed * 0.6, animationSpeed) - 10 * nr,
        easing: (t) => easings.easeInOutSine(t),
      },
      onRest: () => {
        completedAnimationsRef.current += 1;
        allAnimationsDone();
      },
    };
  });

  const allAnimationsDone = React.useCallback(() => {
    if (completedAnimationsRef.current === animatedText.length) {
      setDotVisible(true);
    }
  }, [animatedText]);

  return (
    <div className="logo-4xl from-tablet:logo-6xl flex flex-wrap justify-center items-center text-secondary">
      <span>Better</span>
      <span className="text-active overflow-hidden relative block">
        <span className="opacity-0">{text}</span>
        <span
          aria-hidden="true"
          className="flex absolute top-0 left-0 w-full flex-nowrap"
        >
          {springs.map((props, index) => {
            const char = animatedText[index];
            return (
              <animated.span
                key={index}
                style={props}
                className="flex flex-col"
              >
                <span>{char}</span>
                <span>{char}</span>
                <span>{char}</span>
                <span>{char}</span>
              </animated.span>
            );
          })}
        </span>
        <span
          className={cx(
            "rounded-full w-[7.8px] h-[7.8px] from-tablet:w-[13px] from-tablet:h-[13px] bg-active",
            "origin-center inline-block transition-transform",
            dotVisible ? "scale-100" : "scale-0"
          )}
        ></span>
      </span>
    </div>
  );
}
