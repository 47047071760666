import parse, {
  DOMNode,
  HTMLReactParserOptions,
  domToReact,
} from "html-react-parser";

import getConfig from "next/config";
import * as React from "react";
import { Link } from "../Link";
import { SimpleHtmlBlockProps } from "./type";

function extractPathFromURL(url: string, host: string) {
  if (!host) {
    return url;
  }

  const regex = new RegExp(
    "(?:https?://)?(?:www.)?" + host.replace(/\./g, "\\.") + "(/[^?#]*)"
  );
  const match = url.match(regex);
  if (match && match.length >= 2) {
    return match[1];
  } else {
    return url;
  }
}

export function SimpleHtmlBlock({
  content,
  componentId,
}: SimpleHtmlBlockProps) {
  const { publicRuntimeConfig } = getConfig();
  if (typeof content !== "string" || !content) {
    return null;
  }

  const host = publicRuntimeConfig.PORTAL_HOSTNAME || "";

  const htmlOptions: HTMLReactParserOptions = {
    replace: (domeNode, index) => {
      if (domeNode.type === "tag" && domeNode.name === "a") {
        return (
          <Link
            key={index}
            href={extractPathFromURL(domeNode.attribs.href, host)}
            className={domeNode.attribs.class}
            target={domeNode.attribs.target}
          >
            {domToReact(domeNode.children as DOMNode[], htmlOptions)}
          </Link>
        );
      }
    },
  };

  return (
    <div id={componentId} className="blog-article-content">
      {parse(content, htmlOptions)}
    </div>
  );
}
