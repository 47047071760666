import { Icon, IconType } from "@bs/icons";
import { ContactInformationSocial } from "./types";

interface SocialMap {
  iconType: IconType;
  hoverColor: string;
  title: string;
}

type SocialType = ContactInformationSocial["variant"];

const socialMaps: Record<SocialType, SocialMap> = {
  FACEBOOK: {
    iconType: "facebook",
    hoverColor: "#3b5998",
    title: "Facebook",
  },
  LINKEDIN: {
    iconType: "linkedin",
    hoverColor: "#0077b5",
    title: "Linkedin",
  },
};

export function SocialLink({ link, variant }: ContactInformationSocial) {
  if (!(variant in socialMaps)) {
    return null;
  }

  const { iconType, hoverColor, title } = socialMaps[variant];

  return (
    <div
      // @ts-expect-error missing types for css vars
      style={{ "--socialHoverColor": hoverColor }}
      className="inline-block"
    >
      <a
        href={link?.url}
        target={link?.target}
        title={title}
        className="flex flex-nowrap gap-1 group items-center hover:text-[var(--socialHoverColor)] text-white transition-colors"
      >
        <Icon
          className="group-hover:text-[var(--socialHoverColor) text-secondary"
          name={iconType}
        />
        <span>{title}</span>
      </a>
    </div>
  );
}
