import * as React from "react";

import { BannerAnimationProps } from "../types";

import { Scroll } from "@bs/ui";
import { ActionTrigger } from "../../Action/ActionTrigger";

import { PlayerAnimation, PlayerAnimationRef } from "../Player/PlayerAnimation";

import { cx } from "class-variance-authority";
import { useKeenSlider } from "keen-slider/react";
import { getPercentFromIndex } from "../helpers/getPercentFromIndex";

export function DesktopContent({
  items,
  animationSpeed = 1000,
  animationDelay = 2000,
}: BannerAnimationProps) {
  const playerRef = React.useRef<PlayerAnimationRef | null>(null);
  const scrollContainerRef = React.useRef<HTMLDivElement | null>(null);
  const [opacities, setOpacities] = React.useState<number[]>([]);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const timeout = React.useRef<ReturnType<typeof setTimeout>>();

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      loop: true,

      slides: items.length,
      defaultAnimation: {
        duration: animationSpeed,
        easing: (t) => {
          return 1 + --t * t * t * t * t;
        },
      },
      drag: false,
      slideChanged(s) {
        const index = s.track.details.rel;
        const percent = getPercentFromIndex(index, items.length - 1);

        setCurrentSlide(index);

        if (playerRef?.current) {
          playerRef.current.changeProgress(percent);
        }
      },
      detailsChanged(s) {
        const new_opacities = s.track.details.slides.map(
          (slide) => slide.portion
        );
        setOpacities(new_opacities);
      },
    },
    [
      (slider) => {
        const container = slider.container.parentNode as HTMLElement;
        slider.on("created", () => {
          nextAnimation();
        });
        slider.on("updated", () => {
          nextAnimation();
        });
        slider.on("animationEnded", () => {
          nextAnimation();
        });
        // slider.on("dragStarted", () => {
        //   container.style.setProperty("--animation", "paused");
        //   timeout.current && clearTimeout(timeout.current);
        // });
        // slider.on("dragEnded", () => {
        //   container.style.setProperty("--animation", "running");
        //   nextAnimation();
        // });
        // slider.on("updated", () => {});
      },
    ]
  );

  const nextAnimation = React.useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      instanceRef.current?.next();
    }, animationDelay);
  }, [animationDelay, instanceRef]);

  const onThumbClick = React.useCallback(
    (idx: number) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      instanceRef.current?.moveToIdx(idx);
    },
    [instanceRef]
  );

  return (
    <div ref={scrollContainerRef} className="hidden from-desktop:block">
      <div className="border-secondary border-t-4 border-b-4 my-20 h-[550px] w-full">
        <div className="container p-0 h-full">
          <div className="w-full h-full relative">
            <div className="absolute top-full right-0 w-1 h-full bg-secondary" />
            <div className="absolute bottom-full left-0 w-1 h-full bg-secondary" />
            <div className="w-full h-full flex  items-center">
              <div className="w-1/2 h-full p-10 pl-0">
                <PlayerAnimation ref={playerRef} />
              </div>

              <div className="h-full w-1/2 bg-secondary p-10 flex items-enter">
                <div className=" max-h-full w-full flex relative">
                  <div
                    ref={sliderRef}
                    className="keen-slider fader  max-h-full w-full flex overflow-hidden relative"
                  >
                    {items.map((element, index) => {
                      const zIndex = opacities[index] === 1 ? 2 : 1;
                      return (
                        <div
                          key={index}
                          style={{ opacity: opacities[index], zIndex }}
                          className="absolute top-0 left-0 h-full w-full flex flex-col gap-9 justify-center"
                        >
                          <h2 className="text-3xl text-primary select-none">
                            {element.title}
                          </h2>
                          <div className="overflow-hidden w-full min-h-2">
                            <Scroll className="w-full min-h-2 h-full ">
                              <p
                                className="text-primary select-none"
                                dangerouslySetInnerHTML={{
                                  __html: element.text,
                                }}
                              />
                            </Scroll>
                          </div>
                          {element.action && (
                            <div>
                              <ActionTrigger
                                action={{
                                  ...element.action,
                                  variant: "PRIMARY",
                                  size: "SMALL",
                                }}
                                className="w-full"
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>

                  <div className="w-full h-2 bg-gray absolute -bottom-5 rounded-xl opacity-30 overflow-hidden">
                    {items.length > 1 && (
                      <div className="flex gap-2 w-full items-center justify-center h-full">
                        {items.map((_, index) => {
                          const isActive = index === currentSlide;
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                if (!isActive) {
                                  onThumbClick(index);
                                }
                              }}
                              style={{
                                // @ts-expect-error missing types for css vars
                                "--thumb-animation": `${
                                  animationDelay + animationSpeed
                                }ms`,
                              }}
                              className="transition-all cursor-pointer flex-1 h-full overflow-hidden bg-primary"
                            >
                              {isActive && (
                                <div
                                  className={cx(
                                    "w-full h-full bg-action",
                                    "-translate-y-0 -translate-x-full",
                                    "animate-[progress_var(--thumb-animation)_linear_forwards]",
                                    "[animation-play-state:var(--animation)]"
                                  )}
                                />
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
