import { FormValues } from "./types";

export async function sendMail(formValues: FormValues, reCaptcha: string) {
  try {
    const formData = new FormData();
    for (const key of Object.keys(formValues) as (keyof FormValues)[]) {
      formData.append(key, formValues[key]);
    }

    formData.append("_wpcf7_unit_tag", "b62e1ec");
    formData.append("_wpcf7_recaptcha_response", reCaptcha);

    const response = await fetch(
      "/api/wp-json/contact-form-7/v1/contact-forms/1142/feedback",
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      return false;
    }

    const result = await response.json();
    if (result && result.status === "mail_sent") {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
}
