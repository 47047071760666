import { BaseButton } from "@bs/ui";
import * as React from "react";
import { ActionTriggerProps, ButtonActionData } from "../types";

interface Props extends ActionTriggerProps {
  action: ButtonActionData;
  disabled?: boolean;
}

export function ButtonAction({
  action,
  disabled = false,
  className,
  onClick,
}: Props) {
  const { size, id, variant, title } = action;
  const onClickHandler = React.useCallback(() => {
    if (!disabled && onClick) {
      onClick();
    }
  }, [disabled, onClick]);

  return (
    <BaseButton
      id={id}
      variant={variant}
      size={size}
      onClick={onClickHandler}
      disabled={disabled}
      className={className}
      aria-label={title}
    >
      {title}
    </BaseButton>
  );
}
