import { BaseButton } from "@bs/ui";
import { cx } from "class-variance-authority";
import * as React from "react";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useForm } from "react-hook-form";
import { InView } from "react-intersection-observer";

import { pushDataLayer } from "@bs/data-layer";
import { useContactForm } from "./ContactFormProvider";
import { sendMail } from "./ContactUtils";
import { ContactFormProps, FormValues } from "./types";

const DEFAULT_ANIMATION_TIME = 1200;

export function ContactForm({
  title,
  text: legal,
  actionText,
  withoutMargin = false,
  animationSpeed,
  blogTheme,
}: ContactFormProps) {
  const [isVisible, setVisible] = React.useState(false);
  const [hasActionStart, setHasActionStart] = React.useState(false);

  const ref = React.useRef<HTMLDivElement | null>(null);
  const [token, setToken] = React.useState("");
  const [sendingStatus, setSendingStatus] = React.useState<
    "ERROR" | "PENDING" | "SUCCESS" | null
  >(null);
  const [refreshReCaptcha, setRefreshReCaptcha] = React.useState(false);

  const ANIMATION_TIME = React.useMemo(() => {
    return animationSpeed || DEFAULT_ANIMATION_TIME;
  }, [animationSpeed]);

  const { setRef, sectionRef } = useContactForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm<FormValues>({ mode: "onSubmit" });

  const messageLength = watch("text")?.length || 0; // Tracking message length

  const onSubmit = async (data: FormValues) => {
    if (!hasActionStart) {
      pushDataLayer([
        {
          event: "form_start",
        },
      ]);
      setHasActionStart(true);
    }
    setSendingStatus("PENDING");

    const status = await sendMail(data, token);

    setRefreshReCaptcha(!refreshReCaptcha);

    if (!status) {
      setSendingStatus("ERROR");
      return;
    }

    setSendingStatus("SUCCESS");
    reset();
    pushDataLayer([
      {
        event: "form_sent",
      },
    ]);
  };

  const setTokenFunc = React.useCallback((getToken: string) => {
    setToken(getToken);
  }, []);

  const onFocus = React.useCallback(() => {
    if (hasActionStart) {
      return;
    }

    setHasActionStart(true);
    pushDataLayer([
      {
        event: "form_start",
      },
    ]);
  }, [hasActionStart]);

  React.useEffect(() => {
    if (sectionRef && ref?.current) {
      setRef(ref.current);
    }
  }, [sectionRef, setRef]);

  return (
    <InView triggerOnce={true} onChange={setVisible} rootMargin="-200px">
      <div
        ref={ref}
        className={cx(
          "w-full bg-primary dark:bg-secondary",
          !withoutMargin && "pt-16 from-desktop:pt-24"
        )}
      >
        <div
          className="container relative to-desktop:py-8"
          style={{
            // @ts-expect-error missing types for css vars
            "--contact-form-animation": `${ANIMATION_TIME}ms`,
            "--contact-form-animation-half": `${ANIMATION_TIME / 2}ms`,
            "--contact-form-animation-delay": `${ANIMATION_TIME * 0.2083}ms`,
          }}
        >
          <div className="hidden from-desktop:block">
            <div className="h-full w-1 bg-secondary dark:bg-primary absolute left-0 top-0" />
            <div
              className={cx(
                "absolute bottom-0 left-0 w-1 h-full bg-primary dark:bg-secondary",
                isVisible &&
                  "animate-[tabsHeightAnimation_var(--contact-form-animation-half)_ease_var(--contact-form-animation-delay)_1_normal_forwards]"
              )}
            />
            <div className="h-full w-1 bg-secondary dark:bg-primary absolute right-0 top-0" />
            <div
              className={cx(
                "absolute top-0 right-0 w-1 h-full bg-primary dark:bg-secondary",
                isVisible &&
                  "animate-[tabsHeightAnimation_var(--contact-form-animation-half)_ease_var(--contact-form-animation-half)_1_normal_forwards]"
              )}
            />
          </div>
          <div className="w-full relative flex items-center from-desktop:px-24 from-desktop:h-[90px]">
            <h2 className="text-3xl from-desktop:text-4xl text-secondary dark:text-primary">
              {title}
            </h2>
            <div className="absolute top-0 -left-8 from-l-desktop:-left-[calc((100vw-1120px)/2)] w-screen hidden from-desktop:block">
              <div className="absolute top-0 left-0 h-1 w-full bg-secondary dark:bg-primary" />
              <div
                className={cx(
                  "absolute top-0 right-0 w-full h-1 bg-primary dark:bg-secondary",
                  isVisible &&
                    "animate-[tabsWidthAnimation_var(--contact-form-animation)_ease_1_normal_forwards]"
                )}
              />
            </div>
            <div className="absolute bottom-0 -left-8 from-l-desktop:-left-[calc((100vw-1120px)/2)] w-screen hidden from-desktop:block">
              <div className="absolute top-0 left-0 h-1 w-full bg-secondary dark:bg-primary" />
              <div
                className={cx(
                  "absolute top-0 right-0 w-full h-1 bg-primary dark:bg-secondary",
                  isVisible &&
                    "animate-[tabsWidthAnimation_var(--contact-form-animation)_ease_var(--contact-form-animation-delay)_1_normal_forwards]"
                )}
              />
            </div>
          </div>
          <div className="w-full to-desktop:mt-9 from-desktop:p-24">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex flex-col from-desktop:flex-row gap-8">
                <div className="flex flex-1 flex-col gap-8">
                  <div className="relative w-full">
                    <input
                      type="text"
                      placeholder="Imię"
                      className={cx(
                        "border-2 outline-none bg-primary dark:bg-secondary text-secondary dark:text-primary p-5 text-lg w-full",
                        errors.firstName
                          ? "border-action"
                          : "border-secondary dark:border-primary"
                      )}
                      {...register("firstName", {
                        required: "Imię jest wymagane",
                        pattern: {
                          value: /^[\p{L}\s]+$/u,
                          message: "Nieprawidłowe imię",
                        },
                      })}
                      onFocus={onFocus}
                    />
                    {errors.firstName && (
                      <p className="text-action text-sm  absolute -bottom-[24px] left-0">
                        {errors.firstName.message}
                      </p>
                    )}
                  </div>
                  <div className="relative w-full">
                    <input
                      type="email"
                      placeholder="E-mail"
                      className={cx(
                        "border-2 outline-none bg-primary dark:bg-secondary text-secondary dark:text-primary p-5 text-lg w-full",
                        errors.email
                          ? "border-action"
                          : "border-secondary dark:border-primary"
                      )}
                      {...register("email", {
                        required: "Adres email jest wymagany",
                        pattern: {
                          value: /^\S+@\S+\.\S+$/,
                          message: "Nieprawidłowy adres email",
                        },
                      })}
                      onFocus={onFocus}
                    />
                    {errors.email && (
                      <p className="text-action text-sm  absolute -bottom-[24px] left-0">
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex-1 relative">
                  <textarea
                    placeholder="Treść"
                    className={cx(
                      "w-full h-full resize-none border-2 outline-none bg-primary dark:bg-secondary text-secondary dark:text-primary p-5 text-lg",
                      errors.text
                        ? "border-action"
                        : "border-secondary dark:border-primary"
                    )}
                    {...register("text", {
                      required: "Treść wiadomości jest wymagana",
                      maxLength: {
                        value: 2000,
                        message: "Limit wiadomości to 2000 znaków",
                      },
                    })}
                    onFocus={onFocus}
                  ></textarea>
                  <div className="absolute -bottom-[34px] from-desktop:-bottom-[24px] w-full left-0 flex justify-between items-center gap-1 flex-nowrap flex-row">
                    {errors.text && (
                      <span className="text-action text-sm from-desktop:whitespace-nowrap">
                        {errors.text.message}
                      </span>
                    )}
                    <span className="text-secondary dark:text-primary text-xs text-right ml-auto">
                      Pozostało znaków: <br className="from-desktop:hidden" />
                      {2000 - messageLength > 0 ? 2000 - messageLength : 0}
                    </span>
                  </div>
                </div>
              </div>
              <div className="flex flex-col-reverse from-desktop:flex-row gap-10 from-desktop:gap-8 mt-11 from-desktop:mt-10">
                {legal && (
                  <div className="flex-1">
                    <p
                      className="[&_a]:underline text-secondary dark:text-primary"
                      dangerouslySetInnerHTML={{ __html: legal }}
                    />
                  </div>
                )}
                <div className="flex-1">
                  <BaseButton
                    type="submit"
                    size="LARGE"
                    variant={blogTheme === "LIGHT" ? "PRIMARY" : "SECONDARY"}
                    className="w-full"
                  >
                    {actionText || "Wyślij"}
                  </BaseButton>
                  {sendingStatus && (
                    <>
                      {sendingStatus === "ERROR" && (
                        <span className="text-action text-sm from-desktop:whitespace-nowrap">
                          Wystąpił błąd podczas wysyłania formularza. Spróbuj
                          wysłać jeszcze raz.
                        </span>
                      )}
                      {sendingStatus === "SUCCESS" && (
                        <span className="text-lime-500 text-sm from-desktop:whitespace-nowrap">
                          Formularz wysłany poprawnie!
                        </span>
                      )}
                    </>
                  )}
                </div>
              </div>
              <GoogleReCaptcha
                onVerify={setTokenFunc}
                refreshReCaptcha={refreshReCaptcha}
              />
            </form>
          </div>
        </div>
      </div>
    </InView>
  );
}
