import { BaseButton } from "@bs/ui";
import * as React from "react";
import scrollIntoView from "smooth-scroll-into-view-if-needed";
import { useContactForm } from "../../ContactForm/ContactFormProvider";
import { ActionTriggerProps, ContactFormAction } from "../types";

interface Props extends ActionTriggerProps {
  action: ContactFormAction;
}

export function ContactForm({ action, disabled, className }: Props) {
  const { size, id, variant, title } = action;
  const { sectionRef } = useContactForm();

  const onClick = React.useCallback(() => {
    if (disabled) {
      return;
    }
    if (sectionRef?.current) {
      scrollIntoView(sectionRef.current, {
        behavior: "smooth",
        block: "start",
        inline: "center",
      });
    }
  }, [disabled, sectionRef]);

  return (
    <BaseButton
      id={id}
      variant={variant}
      size={size}
      onClick={onClick}
      disabled={disabled}
      className={className}
    >
      {title}
    </BaseButton>
  );
}
