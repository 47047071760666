import { cx } from "class-variance-authority";

import { LogoCarouselSlider } from "./LogoCarouselSlider";
import { LogoCarouselStatic } from "./LogoCarouselStatic";
import { LogoCarouselProps } from "./types";

export function LogoCarousel({
  title,
  items,
  animateLogo = true,
  ...rest
}: LogoCarouselProps) {
  return (
    <div className="w-full bg-secondary overflow-hidden relative z-10">
      <div className="container">
        <div
          className={cx(
            "flex flex-col from-desktop:flex-row items-center justify-center min-h-16 py-4 from-desktop:min-h-28  from-desktop:py-9",
            title && "gap-6 justify-between"
          )}
        >
          {title && (
            <div className="flex-shrink-0">
              <span className="text-primary text-base from-tablet:text-xl from-desktop:text-3xl">
                {title}
              </span>
            </div>
          )}
          {items.length > 0 && (
            <div
              className={cx("w-full", title && "from-desktop:max-w-[850px]")}
            >
              {animateLogo ? (
                <LogoCarouselSlider dataItems={items} {...rest} />
              ) : (
                <LogoCarouselStatic dataItems={items} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
