import { Icon } from "@bs/icons";
import { Transition } from "@headlessui/react";
import { cx } from "class-variance-authority";
import React from "react";
import { ActionTrigger } from "../Action";
import { FaqItem } from "./types";

interface Props extends FaqItem {
  isLight: boolean;
  isOpen: boolean;
}

export function FAQItem({ title, text, action, isLight, isOpen }: Props) {
  const [expandedElement, setExpandedElement] = React.useState(isOpen);

  const onTriggerClick = React.useCallback(() => {
    setExpandedElement((value) => !value);
  }, []);

  return (
    <div className="w-full pt-3 first:pt-0">
      <div
        className={cx(
          "flex w-full gap-2 items-center text-xl text-left cursor-pointer",
          isLight ? "text-primary" : "text-secondary"
        )}
        onClick={onTriggerClick}
      >
        <h3>{title}</h3>

        <Icon
          name="arrow-right"
          className={cx(
            "h-5 w-5 transition-transform",
            expandedElement ? "rotate-90 transform" : "",
            isLight ? "text-primary" : "text-secondary"
          )}
        />
      </div>
      <Transition
        show={expandedElement}
        enter="transition duration-250 ease-in"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        className="w-full"
      >
        <div
          className={cx(
            "pt-4 flex flex-col gap-4 w-full",
            isLight ? "text-primary" : "text-secondary"
          )}
        >
          <p
            className="text-base from-tablet:text-lg from-desktop:text-base [&_a]:underline"
            dangerouslySetInnerHTML={{ __html: text }}
          />

          {action && (
            <ActionTrigger
              className="w-full from-desktop:w-max"
              action={{
                ...action,
                variant: isLight ? "PRIMARY" : "SECONDARY",
                size: "SMALL",
              }}
            />
          )}
        </div>
      </Transition>
    </div>
  );
}
