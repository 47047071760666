import { useKeenSlider } from "keen-slider/react";
import Image from "next/image";
import * as React from "react";
import { LogoItem } from "./types";

interface Props {
  animationSpeed?: number;
  autoPlay?: boolean;
  animationLoop?: boolean;
  dataItems: LogoItem[];
}

export function LogoCarouselSlider({
  dataItems,
  autoPlay = true,
  animationLoop = true,
  animationSpeed = 10000,
}: Props) {
  const [loadedImagesCount, setLoadedImagesCount] = React.useState(0);
  const items = React.useMemo(() => {
    if (!animationLoop) {
      return [...dataItems];
    }

    return [...dataItems, ...dataItems];
  }, [animationLoop, dataItems]);

  const animation = React.useMemo(() => {
    return { duration: animationSpeed, easing: (t: number) => t };
  }, [animationSpeed]);

  const [sliderRef, sliderApi] = useKeenSlider({
    loop: animationLoop,
    selector: "[data-logo-item]",
    mode: "free-snap",
    renderMode: "performance",
    rtl: false,
    slides: { perView: "auto", spacing: 20 },
    breakpoints: {
      "(min-width: 992px)": {
        slides: { perView: "auto", spacing: 40 },
      },
    },
    created(slider) {
      if (!autoPlay) {
        return;
      }
      slider.moveToIdx(5, true, animation);
    },
    updated(slider) {
      if (!autoPlay) {
        return;
      }
      slider.moveToIdx(slider.track.details.abs + 5, true, animation);
    },
    animationEnded(slider) {
      if (!autoPlay) {
        return;
      }
      slider.moveToIdx(slider.track.details.abs + 5, true, animation);
    },
  });

  React.useEffect(() => {
    if (loadedImagesCount === items.length && sliderApi && sliderApi.current) {
      sliderApi.current.update();
    }
  }, [sliderApi, items.length, loadedImagesCount]);

  const handleImageLoaded = React.useCallback(() => {
    setLoadedImagesCount((count) => count + 1);
  }, []);

  return (
    <div
      ref={sliderRef}
      className="w-full flex from-tablet:overflow-hidden text-primary keen-slider"
    >
      {items.map((item, index) => {
        if (!item.image) {
          return null;
        }

        return (
          <div
            key={index}
            data-logo-item
            className="h-8 from-desktop:h-16 flex-shrink-0 w-auto"
          >
            <Image
              src={item.image}
              alt={item.alt}
              width={0}
              height={0}
              sizes="100vw"
              onLoad={handleImageLoaded}
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        );
      })}
    </div>
  );
}
