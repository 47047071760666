import Image from "next/image";
import { LogoItem } from "./types";

interface Props {
  dataItems: LogoItem[];
}

export function LogoCarouselStatic({ dataItems }: Props) {
  return (
    <div className="w-full flex flex-wrap justify-center items-center relative overflow-hidden text-primary gap-5 from-desktop:gap-10">
      {dataItems.map((item, index) => {
        if (!item.image) {
          return null;
        }

        return (
          <div
            key={index}
            className="h-8 from-desktop:h-16 flex-shrink-0 relative w-auto"
          >
            <Image
              src={item.image}
              alt={item.alt}
              width={0}
              height={0}
              sizes="100vw"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        );
      })}
    </div>
  );
}
