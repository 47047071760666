import { Parallax } from "react-scroll-parallax";
import { ActionTrigger } from "../Action/ActionTrigger";
import { AnimateSlogan } from "./AnimateSlogan";
import { AnimationProps } from "./types";

export function AnimationText({
  animatedText,
  description,
  isStatic,
  action,
  animationSpeed,
}: AnimationProps) {
  const shouldAnimate = isStatic === null || !isStatic;

  return (
    <div className="w-full bg-primary relative overflow-hidden z-10">
      <div className="container relative">
        <div className="h-screen max-h-[512px] flex justify-center items-center flex-col gap-5 text-center relative z-10">
          <div className="whitespace-nowrap">
            {shouldAnimate && (
              <AnimateSlogan
                key={animatedText}
                text={animatedText}
                animationSpeed={animationSpeed}
              />
            )}
            {!shouldAnimate && (
              <div className="only-mobile:text-[7vw] logo-4xl from-tablet:logo-6xl flex flex-wrap text-secondary">
                <span>Better</span>
                <span className="text-active">{animatedText}.</span>
              </div>
            )}
          </div>
          {description && (
            <p className="text-white text-base from-tablet:text-xl">
              {description}
            </p>
          )}
          {action && (
            <div className="mt-7">
              <ActionTrigger
                action={{ ...action, variant: "TERTIARY", size: "BASIC" }}
              />
            </div>
          )}
        </div>
        <div className="absolute -bottom-24 -left-12 from-desktop:-bottom-1/3 from-desktop:-left-1/3 from-desktop:translate-x-full">
          <Circle speed={10} />
        </div>
        <div className="absolute top-0 -right-14 from-desktop:top-1/2 from-desktop:right-1/2 from-desktop:translate-x-full from-desktop:-translate-y-1/2">
          <Circle speed={20} />
        </div>
        <div className="absolute hidden from-tablet:block from-tablet:-bottom-1/4 from-tablet:-right-1/4 from-desktop:-bottom-1/4 from-desktop:-right-14">
          <Circle speed={-10} />
        </div>
      </div>
    </div>
  );
}

function Circle({ speed }: { speed: number }) {
  return (
    <Parallax speed={speed}>
      <div className="w-[400px] h-[400px] bg-secondary/20 rounded-full" />
    </Parallax>
  );
}
