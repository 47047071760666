import { Scroll } from "@bs/ui";
import { cx } from "class-variance-authority";
import { useKeenSlider } from "keen-slider/react";
import * as React from "react";
import { InView } from "react-intersection-observer";
import { ActionTrigger } from "../Action";
import { TabsComponentProps } from "./types";

export function TabsComponent({
  items,
  initialTab,
  extraSpacing = true,
  animationSpeed = 500,
  title,
}: TabsComponentProps) {
  const initTab = React.useMemo(() => {
    if (initialTab && initialTab > 0 && initialTab < items.length) {
      return initialTab;
    }
    return 0;
  }, [initialTab, items.length]);
  const [currentSlide, setCurrentSlide] = React.useState(initTab);
  const [currentTab, setCurrentTab] = React.useState(initTab);

  const [isVisible, setVisible] = React.useState(false);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    selector: "[data-logo-item]",
    initial: initialTab,
    loop: true,
    mode: "free-snap",

    breakpoints: {
      "(max-width: 767.5px)": {
        slides: {
          origin: "center",
          perView: 1,
          spacing: 15,
        },
      },

      "(min-width: 768px)": {
        slides: {
          origin: "center",
          perView: 1.2,
          spacing: 15,
        },
      },
      "(min-width: 992px)": {
        disabled: true,
      },
    },
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel);
    },
    animationEnded(s) {
      setCurrentTab(s.track.details.rel);
    },
  });

  const onTabClick = React.useCallback(
    (idx: number) => {
      setCurrentTab(idx);

      instanceRef.current?.moveToIdx(idx);
    },
    [instanceRef]
  );

  const onThumbsClick = React.useCallback(
    (idx: number) => {
      setCurrentSlide(idx);
      instanceRef.current?.moveToIdx(idx);
    },
    [instanceRef]
  );

  return (
    <InView triggerOnce={true} onChange={setVisible} rootMargin="-200px">
      <div
        className={cx(
          "w-full bg-primary relative z-10 to-desktop:pb-12 to-desktop:pt-8 overflow-hidden",
          extraSpacing ? "from-desktop:pb-24" : "from-desktop:pb-1"
        )}
        style={{
          // @ts-expect-error missing types for css vars
          "--main-animation-duration": `${animationSpeed}ms`,
          "--half-animation-duration": `${animationSpeed * 0.5}ms`,
          "--double-animation-duration": `${animationSpeed * 2}ms`,
          "--bottom-animation-duration": `${animationSpeed * 2.4}ms`,
        }}
      >
        <div className="relative  w-full hidden from-desktop:block">
          <div className="container">
            {title && (
              <h2 className="text-xl from-tablet:text-4xl font-medium pt-6 text-center text-secondary">
                {title}
              </h2>
            )}

            <div className="justify-between relative flex">
              <div className="mt-7 flex-1 min-h-[148px]">
                <div className="flex flex-col gap-8 min-w-[330px]">
                  {items.map((element, index) => {
                    const isActive = index === currentTab;
                    return (
                      <div key={element.id}>
                        <div
                          onClick={() => {
                            onTabClick(index);
                          }}
                          className={cx(
                            "text-xl inline-block cursor-pointer px-5 py-3",
                            "transition-all",
                            isActive && "bg-active text-primary font-bold",
                            !isActive &&
                              "text-gray bg-transparent hover:text-primary hover:bg-action hover:font-bold"
                          )}
                        >
                          {element.name}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="relative w-full h-40 mt-20">
                  <div
                    data-left-top
                    className="absolute top-0 right-0 w-screen h-1 bg-secondary"
                  />
                  <div
                    data-left-top
                    data-info="width to 0"
                    className={cx(
                      "absolute top-0 right-0 w-screen h-1 bg-primary",
                      isVisible &&
                        "animate-[tabsWidthAnimation_var(--main-animation-duration)_ease_var(--half-animation-duration)_1_normal_forwards]"
                    )}
                  />
                </div>
              </div>
              <div className="flex from-desktop:mt-36">
                <div className="relative w-full max-w-40 min-w-8 hidden from-desktop:block">
                  <div
                    data-top-corner-left
                    className="h-1 w-full absolute top-28 right-0 bg-secondary"
                  />
                  <div
                    data-left-corner-left
                    className="h-[calc(100%-112px)] w-1 absolute top-28 left-0 bg-secondary"
                  />
                  <div
                    data-info="to height 4px then to width 0"
                    className={cx(
                      "h-[calc(100%-112px)] w-full absolute top-28 right-0 bg-primary",
                      isVisible &&
                        "animate-[tabsBothAnimation_var(--main-animation-duration)_ease_var(--main-animation-duration)_1_normal_forwards]"
                    )}
                  />
                </div>
                <div className="px-9 py-6 bg-secondary from-desktop:max-w-[640px] from-desktop:min-w-[560px] w-full flex items-center relative">
                  {items.map(({ title, id, text, action }, index) => {
                    const isActive = index === currentTab;
                    return (
                      <div
                        key={id}
                        className={cx(
                          "text-primary flex flex-col gap-9 w-full",
                          isActive ? "animate-fadeIn" : "hidden"
                        )}
                      >
                        <h2 className="text-2xl font-medium">{title}</h2>
                        <Scroll className="w-full min-h-2 max-h-64 h-full">
                          <p
                            className="text-base"
                            dangerouslySetInnerHTML={{ __html: text }}
                          />
                        </Scroll>
                        {action && (
                          <div className="w-full">
                            <ActionTrigger
                              className="w-full"
                              action={{
                                ...action,
                                variant: "PRIMARY",
                                size: "SMALL",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div className="absolute w-[calc((100vw-1120px)/2)] -top-[116px] min-w-10 left-full">
                    <div
                      data-right-corner-top
                      className="absolute top-0 -left-1 w-full h-1 bg-secondary"
                    />
                    <div
                      data-right-corner-left
                      className="absolute top-0 -left-1 w-1 h-[116px] bg-secondary"
                    />
                    <div className="absolute top-0 right-1 w-full h-[116px]">
                      <div
                        data-info="to height 4px then width to 0"
                        className={cx(
                          "absolute top-0 right-0 w-full h-full bg-primary",
                          isVisible &&
                            "animate-[tabsBothAnimation_var(--main-animation-duration)_ease_var(--double-animation-duration)_1_normal_forwards]"
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 left-0 w-screen">
              <div
                data-bottom-to-right
                className="absolute top-0 left-0 h-1 w-full bg-secondary"
              />

              <div
                data-info="to width 0"
                className={cx(
                  "absolute top-0 right-0 w-full h-1 bg-primary",
                  isVisible &&
                    "animate-[tabsWidthAnimation_var(--bottom-animation-duration)_ease_var(--half-animation-duration)_1_normal_forwards]"
                )}
              />
            </div>
          </div>
        </div>
        <div className="container from-desktop:hidden">
          {title && (
            <h2 className="text-xl font-medium pb-8 text-center text-secondary">
              {title}
            </h2>
          )}
          <div className="flex flex-col gap-5">
            <div
              ref={sliderRef}
              className="keen-slider flex from-desktop:max-w-[640px] from-desktop:min-w-[581px] w-full relative"
            >
              {items.map(({ title, id, text, action }) => {
                return (
                  <div
                    key={id}
                    data-logo-item
                    className="text-primary flex flex-col gap-9 px-9 py-6 bg-secondary"
                  >
                    <h2 className="text-xl">{title}</h2>
                    <p
                      className="text-base"
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                    {action && (
                      <div className="w-full mt-auto">
                        <ActionTrigger
                          className="w-full"
                          action={{
                            ...action,
                            variant: "PRIMARY",
                            size: "SMALL",
                          }}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="flex gap-2">
              {items.map(({ id }, index) => {
                const isActive = index === currentSlide;
                return (
                  <div
                    key={id}
                    onClick={() => {
                      onThumbsClick(index);
                    }}
                    className={cx(
                      "flex-1 h-[10px] cursor-pointer",
                      isActive ? "bg-action" : "bg-secondary"
                    )}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </InView>
  );
}
