import { Controls, Player } from "@lottiefiles/react-lottie-player";
import { AnimationItem } from "lottie-web";
import * as React from "react";
import animation from "./animation/animation.json";

export interface PlayerAnimationRef {
  changeProgress(progress: number): void;
}

export const PlayerAnimation = React.forwardRef<PlayerAnimationRef, unknown>(
  function PlayerAnimation(_, ref) {
    const lottieRef = React.useRef<null | AnimationItem>(null);

    const state: {
      timeoutId?: NodeJS.Timeout;
    } = React.useMemo(() => {
      return {};
    }, []);

    function move(progress: number) {
      if (!lottieRef?.current) {
        clearTimeout(state.timeoutId);
        return;
      }
      const totalFrames = 1.35; //total to 1.48 ale są źle podzielone klatki
      const goToFrame = progress * totalFrames;

      const currentFrame = lottieRef.current.currentRawFrame;

      const direction = goToFrame > currentFrame ? 1 : -1;
      const nextFrame = currentFrame + direction;

      if (
        (direction === 1 && nextFrame > goToFrame) ||
        (direction === -1 && nextFrame < goToFrame)
      ) {
        clearTimeout(state.timeoutId);
        return;
      }

      lottieRef.current.goToAndStop(nextFrame, true);

      state.timeoutId = setTimeout(() => {
        move(progress);
      }, 10);
    }

    React.useImperativeHandle(ref, () => ({
      changeProgress: (progress: number) => {
        if (!lottieRef?.current) {
          return;
        }

        clearTimeout(state.timeoutId);
        setTimeout(() => {
          move(progress);
        }, 0);
      },
    }));

    return (
      <div className="relative w-full h-full overflow-hidden aspect-h-1">
        <div className="absolute w-[110%] h-[110%] top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <Player
            lottieRef={(instance) => {
              lottieRef.current = instance;
            }}
            autoplay={false}
            loop={false}
            controls={false}
            keepLastFrame={true}
            src={animation}
            style={{ height: "100%", width: "100%" }}
          >
            <Controls
              visible={true}
              buttons={["play", "repeat", "frame", "debug"]}
            />
          </Player>
        </div>
      </div>
    );
  }
);
